.column{
    margin-top:100px;
}



.center{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    flex-direction: column;
}

.center-text{
    align-items: center;
    text-align: center;
    padding-top: 10px;
}

.img-center{
    align-items: center;
    padding-left: 3.5cm;
    padding-bottom: 50px;
    padding-top: 50px;
}

@media all and (max-width: 40em){
    .desktop {
        display: none;
    }

}

@media not all and (max-width: 40em) {
    .mobile {
        display: none;
    }

}

html {
    overflow: hidden;
}

.desktop-logo{
    width: 30%;
    padding-bottom: 70px;
}



.image-size{
    /* width: 40%; */
    /* padding-bottom: 10%; */
    /* object-fit: fill; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    width: 100%;
    height: 70%;
    
}

.logo{
    width: 70%;
    padding: 1.5cm;
}

.App {
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 1em;
    text-align: left;
    width: 600px;
}

.form {
    padding: 1em;
    width: 11.8cm;
}

label {
    font-weight: 600;
}

.form-size{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.padding{
    padding-left: 20px;
}

body {
    margin: 0;
}


.input{
    border-color: #60A7A3;
}

.button{
    background-color: #60A7A3;
}



