.container-bg{
    background-color: white;
}
.margin{
    margin-top: 150px;
}

.row-table{
    flex-direction: row;
    padding: 50px;
    direction: ltr;
    
}

/* .padding{
    padding-left: 570px;
    padding-top: 220px;
} */
/* 
body{
    background-color: #60A7A3;
    background-image: linear-gradient(315deg, #8BC6EC 0%, #60A7A3 100%);
} */

/* .input{
    width: 50%;
} */

.centered{
display: flex;
justify-content: center;
}
/* Send Message */ 


.content .container-bg{
   
  border-radius: 25px;

}

.center{
    margin-left: auto;
    margin-right: auto;
}

.pad{
    padding-left: 30px;
}